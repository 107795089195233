import { MoreOutlined } from '@ant-design/icons'
import {
    IconGraph,
    IconPlay,
    IconStop,
    IconTrash
} from '@cms/core/assets/icons'
import { Button, Dropdown, Menu, Progress, Row, Typography } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Status from '../Status'
import { Actions, WrapperSurvey, WrapperText } from './styles'
import { useModulePermissions } from '@cms/core/components/Providers/ModulePermissionProvider'

const { Text } = Typography

const QuizCard = ({
    quiz,
    path,
    handleDelete,
    handlePlayQuiz,
    handleFinishQuiz,
    refetch
}) => {
    const [percentage, setPercentage] = useState(0)
    const { hasActions } = useModulePermissions()

    const handleIcon = (status) => {
        if (status) {
            return (
                <Button
                    onClick={() => {
                        handleFinishQuiz(quiz.node.id), setPercentage(1)
                    }}
                >
                    <IconStop />
                </Button>
            )
        } else {
            return (
                <Button
                    onClick={() => {
                        handlePlayQuiz(quiz.node.id), setPercentage(100)
                    }}
                >
                    <IconPlay />
                </Button>
            )
        }
    }
    const menu = (
        <Menu>
            <Menu.Item key="1">
                {hasActions('write') ? (
                    <Link to={path + `/survey/${quiz.node.id}/editar`}>
                        Editar Enquete
                    </Link>
                ) : (
                    <Link to={path + `/survey/${quiz.node.id}/visualizar`}>
                        Visualizar Enquete
                    </Link>
                )}
            </Menu.Item>
        </Menu>
    )
    useEffect(() => {
        const timer = window.setInterval(() => {
            setPercentage(
                handleDatePercentage(quiz.node.startAt, quiz.node.endAt)
            )
        }, 1000)
        return () => {
            window.clearInterval(timer)
        }
    }, [percentage])

    useEffect(() => {
        const refetchWhenFinished = async () => {
            if (percentage === 100) {
                await refetch()
            }
        }
        refetchWhenFinished()
    }, [percentage])

    const handleDatePercentage = (startAt, endAt) => {
        let start = new Date(startAt)
        let end = new Date(endAt)
        let now = new Date()
        const percentage = Math.round(((now - start) / (end - start)) * 100)
        if (!Number.isFinite(percentage)) return 0
        if (percentage > 100) {
            return 100
        }

        return percentage
    }
    return (
        <Row key={quiz.node.id}>
            <WrapperSurvey key={quiz.node.id}>
                <Row justify="space-between">
                    <Status status={quiz.node.status} />
                    {!quiz.node.start && (
                        <Dropdown placement="bottomRight" overlay={menu}>
                            <MoreOutlined style={{ cursor: 'pointer' }} />
                        </Dropdown>
                    )}
                </Row>
                <WrapperText key={quiz.node.id}>
                    <div style={{ display: 'grid' }}>
                        <Text strong>{quiz.node.question}</Text>
                        <Text>
                            {quiz.node.startAt &&
                                dayjs(quiz.node.startAt).format('D MMM YYYY')}
                        </Text>
                    </div>
                    <Actions>
                        {quiz.node.status !== 'FINISHED' &&
                            hasActions('write') &&
                            handleIcon(quiz.node.start)}
                        <Link to={path + `/survey/${quiz.node.id}/report`}>
                            {' '}
                            <IconGraph />{' '}
                        </Link>
                        {hasActions('delete') && (
                            <Button onClick={() => handleDelete(quiz.node.id)}>
                                <IconTrash />
                            </Button>
                        )}
                    </Actions>
                </WrapperText>
                <Progress
                    percent={percentage}
                    showInfo={false}
                    status={'success'}
                />
            </WrapperSurvey>
        </Row>
    )
}

export default QuizCard
