import dayjs from 'dayjs'

export const formatMoneyReal = (value) => {
    if (!value) return

    return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value)
}

export const shouldFilterByHour = (dateRange) => {
    if (!dateRange.startDate || !dateRange.endDate) {
        return false
    }

    const startDate = dayjs(dateRange.startDate)
    const endDate = dayjs(dateRange.endDate)

    return endDate.diff(startDate, 'day') === 1
}

