import React from 'react'
import { Button, Form, Input, Modal, Select } from 'antd'
import { nanoid } from 'nanoid'
import Modals from '../../../modals'
import { usePageBuilderContext } from '../../../context/context'

export interface IindexProps {
    site: any
    form: any
    onCreate: (values: any) => void
    onCancel: () => void
    open: boolean
}

export function AddModalModalComponent({
    site,
    open,
    form,
    onCreate,
    onCancel
}: IindexProps) {
    const { event } = usePageBuilderContext()
    return (
        <Modal
            open={open}
            title="Adicionar modal"
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancelar
                </Button>,
                <Button
                    data-cy="save-button"
                    key="submit"
                    type="primary"
                    onClick={() => {
                        form.validateFields()
                            .then((values) => {
                                form.resetFields()
                                onCancel()
                                Modals.Form.create(site, event).then(
                                    (configs) => {
                                        const modalValues = {
                                            id: nanoid(),
                                            type: 'Form',
                                            configs,
                                            ...values
                                        }
                                        onCreate(modalValues)
                                    }
                                )
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info)
                            })
                    }}
                >
                    Adicionar modal
                </Button>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                name="addModal"
                initialValues={{ modifier: 'public' }}
            >
                <Form.Item
                    label="Nome do modal"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}
