import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import EventModal from '@cms/events/components/Event/EventModal'

import Main from './Main'
import Events from './Events'
// import Admin from './Admin'
import Sponsor from './Sponsor'
import Route404 from '@cms/core/routes/404Route'
import SponsorDetail from './SponsorDetail'
import { AppConfigProvider } from '@cms/core/components/Providers/AppConfigProvider'

const Help = () => {
    return (
        <Route
            path="/help"
            component={() => {
                window.location.href =
                    'https://transformacaodigital.zohodesk.com/portal/pt-br/kb'
            }}
        />
    )
}

const Container = () => {
    const showModal = useSelector((state) => state.showModalEvent)
    const dispatch = useDispatch()
    const isAdmin = useSelector((state) => state.user.isAdmin)
    const isSponsor = useSelector((state) => state.user.role === 'sponsor')

    return (
        <AppConfigProvider>
            {isAdmin && (
                <Switch>
                    <Redirect exact path="/" to={{ pathname: '/principal' }} />
                    <Route path="/principal" component={Main} />
                    <Route path="/evento/:eventId" component={Events} />
                    <Help />
                    <Route404 />
                </Switch>
            )}
            {isSponsor && (
                <Switch>
                    <Redirect exact path="/" to={{ pathname: '/principal' }} />
                    <Route path="/principal" component={() => <Sponsor />} />
                    <Route
                        path="/evento/:eventId"
                        component={() => <SponsorDetail />}
                    />
                    <Help />
                    <Route404 />
                </Switch>
            )}
            {showModal && <EventModal eventId={showModal} />}
        </AppConfigProvider>
    )
}

export default Container

