import { gql } from '@apollo/client'
import { EventFormData } from '../fragments'
/*
 * Forms type register | sponsor
 */
export const GET_EVENT_FORMS = gql`
    query GetEventForms(
        $eventId: ObjectID!
        $type: EventFormType
        $search: String
    ) {
        eventForms(filter: { event: $eventId, type: $type }, search: $search) {
            totalCount
            edges {
                node {
                    ...EventFormData
                }
            }
        }
    }

    ${EventFormData}
`

export const GET_EVENT_FORM = gql`
    query GetEventForm($formId: ObjectID!) {
        eventForm(id: $formId) {
            ...EventFormData
        }
    }

    ${EventFormData}
`

export const GET_EVENT_FORM_DATA = gql`
    query GetEventFormData(
        $formId: ObjectID
        $userId: ObjectID
        $eventId: ObjectID
        $first: Int
        $afterCursor: Cursor
    ) {
        eventFormsData(
            first: $first
            after: $afterCursor
            filter: { form: $formId, user: $userId, event: $eventId }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    form {
                        type
                        name
                        fields {
                            name
                            label
                            type
                        }
                    }
                    data
                    user {
                        id
                        name
                        email
                        phone
                        company
                        jobTitle
                        department
                        role
                        networks {
                            totalCount
                        }
                        avatar {
                            url
                        }
                        createdAt
                    }
                    context
                    createdAt
                }
            }
        }
    }
`
