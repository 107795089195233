import { Col, Row } from 'antd'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import FinancialOrderMetric from './FinancialOrderMetrics'
import FinancialChart from './FinancialChart'
import FinancialPayments from './FinancialPayments'
import FinancialTickets from './FinancialTickets'

import dayjs from 'dayjs'
import FinancialFilterByDate from './FinancialFilterByDate'

const today = dayjs()

export default function Financial() {
    const { eventId } = useParams()

    const [dateOption, setDateOption] = useState('today')
    const [dateRange, setDateRange] = useState({
        startDate: today.format('YYYY-MM-DD'),
        endDate: today.add(1, 'day').format('YYYY-MM-DD')
    })

    return (
        <>
            <FinancialFilterByDate
                dateOption={dateOption}
                setDateOption={setDateOption}
                setDateRange={setDateRange}
            />
            <FinancialOrderMetric eventId={eventId} dateRange={dateRange} />
            <FinancialChart eventId={eventId} dateRange={dateRange} />
            <Row gutter={[12, 12]}>
                <Col span={15}>
                    <FinancialPayments
                        eventId={eventId}
                        dateRange={dateRange}
                    />
                </Col>
                <Col span={9}>
                    <FinancialTickets eventId={eventId} dateRange={dateRange} />
                </Col>
            </Row>
        </>
    )
}

