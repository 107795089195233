import { Form } from 'antd'
import React from 'react'
import { usePageBuilderContext } from '../../context/context'

import { AddPageModalComponent } from './Page'
import { AddModalModalComponent } from './Modal'

const AddModal = ({ open, onCreate, onCancel, type }) => {
    const { site } = usePageBuilderContext()
    const [form] = Form.useForm()

    if (type === 'pages') {
        return (
            <AddPageModalComponent
                site={site}
                form={form}
                open={open}
                onCreate={onCreate}
                onCancel={onCancel}
            />
        )
    }

    if (type === 'modals') {
        return (
            <AddModalModalComponent
                site={site}
                form={form}
                open={open}
                onCreate={onCreate}
                onCancel={onCancel}
            />
        )
    }

    return null
}

export default AddModal
