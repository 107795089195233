import React, { useState } from 'react'
import { Switch } from 'react-router-dom'
import PrivateRoute from '@cms/core/routes/privateRoute'

import Event from './event'
import Analytics from './Analytics'
import Sponsors from './Sponsors'
import Conversions from './Conversions'
import General from './General'
import Financial from './Financial'

const baseUrl = '/evento/:eventId'

export const EventRoutes = () => {
    return (
        <>
            <Switch>
                <PrivateRoute
                    exact
                    path={baseUrl + '/dashboard'}
                    component={Conversions}
                />
                <PrivateRoute
                    exact
                    path={baseUrl + '/dashboard/visao-geral'}
                    component={General}
                />
                <PrivateRoute
                    exact
                    path={baseUrl + '/dashboard/tempo-real'}
                    component={Analytics}
                />
                <PrivateRoute
                    exact
                    path={baseUrl + '/dashboard/patrocinadores'}
                    component={Sponsors}
                />

                <PrivateRoute
                    exact
                    path={baseUrl + '/dashboard/financeiro'}
                    component={Financial}
                />
            </Switch>
        </>
    )
}

export const EventRouteMain = () => {
    return (
        <>
            <Switch>
                <PrivateRoute path={baseUrl + '/dashboard'} component={Event} />
            </Switch>
        </>
    )
}

export default EventRoutes

