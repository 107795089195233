import React from 'react'
import { Col, Row } from 'antd'

import { TDDashboardCard } from '@cms/core/components/TDCards'
import FinancialOrderMetricCard from './Card'
import { useQuery } from '@apollo/client'
import {
    GET_SALES_BY_PAYMENT_STATUS_FINANCIAL,
    GET_SALES_BY_PAYMENT_TYPE_FINANCIAL
} from '@cms/events/graphql/queries/financial'
import { formatMoneyReal } from '../utils'

export default function FinancialOrderMetric({ eventId, dateRange }) {
    const { data } = useQuery(GET_SALES_BY_PAYMENT_STATUS_FINANCIAL, {
        variables: { eventId, ...dateRange }
    })

    const { data: dataPaymentType } = useQuery(
        GET_SALES_BY_PAYMENT_TYPE_FINANCIAL,
        {
            variables: { eventId, ...dateRange, status: 'pending' }
        }
    )

    const sales = data?.salesByPaymentStatus
    const paymentType = dataPaymentType?.salesByPaymentType

    const paid = sales?.find((sp) => sp.status === 'paid')
    const pending = sales?.find((sp) => sp.status === 'pending')

    const pix = paymentType?.find((s) => s.type === 'pix')
    const boleto = paymentType?.find((s) => s.type === 'boleto')

    return (
        <Row gutter={[24, 24]} style={{ marginBottom: '12px' }}>
            <Col span={6}>
                <TDDashboardCard>
                    <FinancialOrderMetricCard
                        title={'Pago'}
                        data={formatMoneyReal(paid?.value) || '0,00'}
                        currency="R$"
                        full={false}
                    />
                </TDDashboardCard>
            </Col>
            <Col span={6}>
                <TDDashboardCard>
                    <FinancialOrderMetricCard
                        title={'Pendente'}
                        data={formatMoneyReal(pending?.value) || '0,00'}
                        currency="R$"
                        full={false}
                    />
                </TDDashboardCard>
            </Col>
            <Col span={6}>
                <TDDashboardCard>
                    <FinancialOrderMetricCard
                        title={'Pendente Pix'}
                        data={formatMoneyReal(pix?.total) || '0,00'}
                        currency="R$"
                        full={false}
                    />
                </TDDashboardCard>
            </Col>
            <Col span={6}>
                <TDDashboardCard>
                    <FinancialOrderMetricCard
                        title={'Pendente Boleto'}
                        data={formatMoneyReal(boleto?.total) || '0,00'}
                        currency="R$"
                        full={false}
                    />
                </TDDashboardCard>
            </Col>
        </Row>
    )
}

