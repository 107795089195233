import React from 'react'
import { MoreOutlined } from '@ant-design/icons'
import { IconTrash } from '@cms/core/assets/icons'
import { Button, Dropdown, Menu, Row, Switch, Typography } from 'antd'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import TalkNotificationStatus from '../TalkNotificationStatus'
import { useModulePermissions } from '@cms/core/components/Providers/ModulePermissionProvider'

import {
    Actions,
    WrapperSurvey,
    WrapperSwitch,
    WrapperText,
    WrapperTitle
} from './styles'

const { Text } = Typography

const NotificationCard = ({
    notification,
    handleDelete,
    handleChat,
    handlePopup,
    handleSendNotification,
    path
}) => {
    const { hasActions } = useModulePermissions()
    const menu = (
        <Menu>
            <Menu.Item key="1">
                {hasActions('write') ? (
                    <Link
                        to={
                            path +
                            `/publicidade/notificacoes/${notification.node.id}/editar`
                        }
                    >
                        Editar Notificação
                    </Link>
                ) : (
                    <Link
                        to={
                            path +
                            `/publicidade/notificacoes/${notification.node.id}/visualizar`
                        }
                    >
                        Visualizar Notificação
                    </Link>
                )}
            </Menu.Item>
        </Menu>
    )
    return (
        <Row key={notification.node.id}>
            <WrapperSurvey key={notification.node.id}>
                <Row justify="space-between">
                    <TalkNotificationStatus status={notification.node.active} />
                    <Dropdown placement="bottomRight" overlay={menu}>
                        <MoreOutlined style={{ cursor: 'pointer' }} />
                    </Dropdown>
                </Row>
                <WrapperText key={notification.node.id}>
                    <WrapperTitle>
                        <Text strong>{notification.node.title}</Text>
                        <Text>
                            {notification.node.createdAt &&
                                dayjs(notification.node.createdAt).format(
                                    'D MMM YYYY'
                                )}
                        </Text>
                    </WrapperTitle>
                    <div style={{ display: 'grid' }}>
                        <WrapperSwitch>
                            <Switch
                                checked={notification.node.chat}
                                onClick={(checked) =>
                                    handleChat(notification.node.id, checked)
                                }
                                defaultChecked
                                disabled={!hasActions('write')}
                            />
                            <Text strong>Chat</Text>
                        </WrapperSwitch>
                        <WrapperSwitch>
                            <Switch
                                checked={notification.node.popup}
                                onClick={(checked) =>
                                    handlePopup(notification.node.id, checked)
                                }
                                defaultChecked
                                disabled={!hasActions('write')}
                            />
                            <Text strong>Popup Transmissão</Text>
                        </WrapperSwitch>
                    </div>
                    <Actions>
                        {hasActions('write') && (
                            <Button
                                onClick={() =>
                                    handleSendNotification(notification.node.id)
                                }
                            >
                                Enviar Notificação
                            </Button>
                        )}

                        {hasActions('delete') && (
                            <Button
                                className="cancel-button"
                                onClick={() =>
                                    handleDelete(notification.node.id)
                                }
                            >
                                <IconTrash />
                            </Button>
                        )}
                    </Actions>
                </WrapperText>
            </WrapperSurvey>
        </Row>
    )
}

export default NotificationCard
