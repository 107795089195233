import ModalFormType from './Form'

export const FormModals = {
    Form: {
        Component: ModalFormType,
        create: async (site, event) => {
            return {
                formId: event.formRegister.id,
                box: {
                    bgColor: 'white',
                    textColor: 'black'
                },
                headline: {
                    text: ''
                },
                button: {
                    bgColor: 'primary',
                    textColor: 'white'
                },
                countdown: {
                    date: event.startDate,
                    text: 'As vagas acabam em:',
                    enabled: true
                }
            }
        }
    }
}
