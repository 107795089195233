import React from 'react'
import { useSelector } from 'react-redux'
import { findModule, selectModules, selectFullAccess } from '../Sidebar/common'
import { AllAppsModules } from '@cms/core/Apps'

export const isModuleAllowed = (
    state,
    module,
    action = '',
    modules = AllAppsModules
) => {
    const isFullAccess = selectFullAccess(state)

    if (isFullAccess) return true

    const _modules = selectModules(state, modules)
    const _module = findModule(module + (action ? ':' + action : ''), _modules)

    if (!_module) return false

    return !!_module
}

export const useCheckModule = (module, action = '') => {
    const allowed = useSelector((state) =>
        isModuleAllowed(state, module, action)
    )

    return allowed
}

export const CheckModule = ({ module = '', action = '', children }) => {
    const allowed = useCheckModule(module, action)

    if (allowed) {
        return <>{children}</>
    }

    return null
}

export const useCheckModuleByModule = (
    module,
    action = '',
    modules = AllAppsModules
) => {
    const allowed = useSelector((state) => {
        const isFullAccess = selectFullAccess(state)

        if (isFullAccess) return true

        const _modules = selectModules(state, modules)
        const _module = findModule(module, _modules, { key: 'module' })

        if (!_module) return false

        if (action) {
            if (!_module._children) return false

            return !!findModule(
                _module.module + (action ? ':' + action : ''),
                _modules
            )
        }

        return !!_module
    })

    return allowed
}

export const useCheckModuleByPath = (
    path,
    action = '',
    modules = AllAppsModules
) => {
    const allowed = useSelector((state) => {
        const isFullAccess = selectFullAccess(state)

        if (isFullAccess) return true

        const _modules = selectModules(state, modules)
        const _module = findModule(path, _modules, { key: 'path' })

        if (!_module) return false

        if (action) {
            if (!_module._children) return false

            return !!findModule(
                _module.module + (action ? ':' + action : ''),
                _modules
            )
        }

        return !!_module
    })

    return allowed
}

export const CheckModuleByPath = ({ path = '', action = '', children }) => {
    const allowed = useCheckModuleByPath(path, action)

    if (allowed) {
        return children
    }

    return null
}
