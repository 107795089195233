import { Modal } from 'antd'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ModalContent from './modalContent'
import { useModulePermissions } from '@cms/core/components/Providers/ModulePermissionProvider'

const Surveys = ({ params, path, eventId, talkId, ...props }) => {
    const history = useHistory()
    const { hasActions } = useModulePermissions()

    useEffect(() => {
        document.body.style.overflowY = 'hidden'
        return () => {
            document.body.style.overflowY = ''
        }
    }, [])

    const handleCancelModal = () => {
        history.push(path)
    }
    const handleOk = () => {
        if (!hasActions('write')) return
        history.push(path + '/survey/adicionar')
    }

    return (
        <Modal
            open={true}
            onOk={handleOk}
            okText={'Nova enquete'}
            okButtonProps={{ disabled: !hasActions('write') }}
            cancelText={'Fechar'}
            onCancel={handleCancelModal}
            width={600}
            title={'Gerenciamento de enquete'}
            children={
                <ModalContent talkId={talkId} path={path} eventId={eventId} />
            }
            {...props}
        />
    )
}

export default Surveys
