import React, { useState } from 'react'
import { RiseOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Line,
    LineChart
} from 'recharts'

import {
    ContainerChart,
    DashboardCardTitle,
    DashboardTitle,
    IconWrapper
} from '@cms/events/components/Dashboard/styles'
import Loader from '@cms/core/components/Loader'
import { Empty, Row, Select } from 'antd'
import { useQuery } from '@apollo/client'
import { TDDashboardCard } from '@cms/core/components/TDCards'
import { GET_SALES_DAILY_OVERRAL_PERFORMANCE_FINANCIAL } from '@cms/events/graphql/queries/financial'
import { shouldFilterByHour } from '../utils'

export default function FinancialChart({ eventId, dateRange }) {
    const [type, setType] = useState('vTotal')

    const { data, error } = useQuery(
        GET_SALES_DAILY_OVERRAL_PERFORMANCE_FINANCIAL,
        {
            variables: {
                eventId,
                ...dateRange,
                byHour: shouldFilterByHour(dateRange)
            }
        }
    )

    if (error) return `Error! ${error.message}`
    if (!data) return <Loader fixed={false} padding="10px" />

    let startDate, endDate

    if (
        data.salesDailyOverallPerformance &&
        data.salesDailyOverallPerformance.length > 0
    ) {
        const items = data.salesDailyOverallPerformance
        startDate = new Date(items[items.length - 1].date).getTime()
        endDate = new Date(items[0].date).getTime()
    }

    return (
        <TDDashboardCard style={{ marginBottom: '12px' }}>
            <ContainerChart>
                <Row justify="space-between">
                    <DashboardCardTitle>
                        <IconWrapper>
                            <RiseOutlined style={{ color: '#fff' }} />
                        </IconWrapper>
                        <DashboardTitle>
                            {type === 'vTotal'
                                ? 'Volume total transacionado'
                                : 'Número de cobranças'}
                        </DashboardTitle>
                    </DashboardCardTitle>
                    <Select
                        placeholder="Selecione"
                        value={type}
                        onChange={(value) => setType(value)}
                    >
                        <Select.Option value="vTotal">
                            Volume total transacionado
                        </Select.Option>
                        <Select.Option value="numCobr">
                            Número de cobranças
                        </Select.Option>
                    </Select>
                </Row>

                <ResponsiveContainer width="100%" height={300}>
                    {data.salesDailyOverallPerformance &&
                    data.salesDailyOverallPerformance.length > 0 ? (
                        <LineChart
                            data={data.salesDailyOverallPerformance.map(
                                (values) => ({
                                    ...values,
                                    total:
                                        type === 'vTotal'
                                            ? values.volume
                                            : values.count
                                })
                            )}
                            margin={{
                                top: 30,
                                right: 10,
                                left: -10,
                                bottom: 0
                            }}
                        >
                            <XAxis
                                domain={[startDate, endDate]}
                                dataKey="date"
                                tickFormatter={(v) =>
                                    dayjs
                                        .utc(v)
                                        .format(
                                            shouldFilterByHour(dateRange)
                                                ? 'HH:mm'
                                                : 'DD/MM/YYYY'
                                        )
                                }
                                tickCount={30}
                                axisLine={false}
                                tickLine={false}
                                tickMargin={10}
                            />
                            <YAxis
                                dataKey="total"
                                axisLine={false}
                                tickLine={false}
                                padding={{ right: 30 }}
                            />

                            <CartesianGrid
                                vertical={false}
                                stroke="rgba(255, 255, 255, 0.1)"
                                strokeDasharray="2 0"
                            />
                            <Tooltip
                                labelFormatter={(v) =>
                                    dayjs
                                        .utc(v)
                                        .format(
                                            shouldFilterByHour(dateRange)
                                                ? 'HH:mm'
                                                : 'DD/MM/YYYY'
                                        )
                                }
                            />
                            <Legend />

                            <Line
                                type="monotone"
                                stroke="#00D394"
                                name={
                                    type === 'vTotal'
                                        ? 'Volume total transacionado'
                                        : 'Número de cobranças'
                                }
                                dataKey="total"
                                strokeWidth={5}
                                dot={false}
                            />
                        </LineChart>
                    ) : (
                        <Empty />
                    )}
                </ResponsiveContainer>
            </ContainerChart>
        </TDDashboardCard>
    )
}

