import React from 'react'
import { Button, Col, Collapse, Form, Row, Tooltip } from 'antd'

import {
    DeleteOutlined,
    PlusOutlined,
    InfoCircleOutlined
} from '@ant-design/icons'
import {
    ButtonConfig,
    ColorsBadges,
    ContainerConfig,
    Uploader
} from '../../../components'
import { Wrapper, Title } from '../../../styles'
import RichTextEditor from '../../../components/RichTextEditor/Editor'
import { nanoid } from 'nanoid'

const { Panel } = Collapse

const Galery4 = (props) => {
    return (
        <>
            <ContainerConfig {...props} bgVideo={false} />

            <Wrapper>
                <Title level={4}>
                    Imagens{' '}
                    <Tooltip
                        title={`Tamanho de imagem recomendado: 1920x1080 ou
                        proporção 16:9.`}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </Title>

                <Form.List name={[props.name, 'configs', 'galery', 'items']}>
                    {(fields, { add, remove }) => (
                        <>
                            <Collapse>
                                {fields.map(
                                    ({ key, name, ...restField }, i) => (
                                        <Panel
                                            header={i + 1 + 'º Slide'}
                                            key={key}
                                            extra={
                                                <DeleteOutlined
                                                    onClick={() => remove(name)}
                                                />
                                            }
                                        >
                                            <Row justify={'space-evenly'}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name={[
                                                            name,
                                                            'image',
                                                            'src'
                                                        ]}
                                                        label="Imagem de fundo slide"
                                                    >
                                                        <Uploader />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={12}>
                                                    <Form.Item
                                                        name={[
                                                            name,
                                                            'mobileImage',
                                                            'src'
                                                        ]}
                                                        label="Imagem de fundo mobile"
                                                    >
                                                        <Uploader />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Form.Item
                                                name={[name, 'logo', 'src']}
                                                label="Pequena logo"
                                            >
                                                <Uploader />
                                            </Form.Item>

                                            <Form.Item
                                                name={[
                                                    name,
                                                    'headline',
                                                    'text'
                                                ]}
                                                label="Título"
                                            >
                                                <RichTextEditor />
                                            </Form.Item>

                                            <Form.Item
                                                name={[
                                                    name,
                                                    'subtitle',
                                                    'text'
                                                ]}
                                                label="Subtítulo"
                                            >
                                                <RichTextEditor />
                                            </Form.Item>

                                            <Form.Item
                                                name={[
                                                    name,
                                                    'smallText',
                                                    'text'
                                                ]}
                                                label="Texto topo"
                                            >
                                                <RichTextEditor />
                                            </Form.Item>

                                            <ButtonConfig
                                                enableActions
                                                allowVisibilityControl
                                                fieldPath={[name, 'button']}
                                                rootPath={[
                                                    'sections',
                                                    props.name,
                                                    'configs',
                                                    'galery',
                                                    'items'
                                                ]}
                                                {...props}
                                            />
                                        </Panel>
                                    )
                                )}
                            </Collapse>

                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() =>
                                        add({
                                            id: nanoid()
                                        })
                                    }
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Adicionar slide
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Form.Item
                    name={[props.name, 'configs', 'dots', 'textColor']}
                    label="Cor dos pontos de navegação"
                >
                    <ColorsBadges />
                </Form.Item>
            </Wrapper>
        </>
    )
}

export default Galery4
