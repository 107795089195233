import { Categories } from './Categories'
import { FeaturedCategories } from './FeaturedCategories'

export const CategoriesSections = {
    categories: {
        Component: Categories,
        name: 'categories',
        category: 'Categorias',
        label: 'Categorias',
        description: 'Seção sem filtro predefinidos',
        defaultConfig: {
            title: {
                'pt-PT': 'Categorias',
                'pt-BR': 'Categorias',
                'en-US': 'Categories',
                'es-ES': 'Categorias'
            },
            condition: {
                type: 'AND',
                filters: []
            }
        }
    },
    featuredCategories: {
        Component: FeaturedCategories,
        name: 'featuredCategories',
        category: 'Categorias',
        label: 'Categorias destaque',
        description: 'Seção sem filtro predefinidos',
        defaultConfig: {
            title: {
                'pt-PT': 'Categorias destaque',
                'pt-BR': 'Categorias destaque',
                'en-US': 'Featured Categories',
                'es-ES': 'Categorias destaque'
            },
            condition: {
                type: 'AND',
                filters: []
            }
        }
    }
}
