import { gql } from '@apollo/client'

export const GET_SALES_BY_TICKET_TYPE_FINANCIAL = gql`
    query GetSalesByTicketType(
        $eventId: ObjectID!
        $startDate: Date!
        $endDate: Date
    ) {
        salesByTicketType(
            eventId: $eventId
            startDate: $startDate
            endDate: $endDate
        ) {
            ticketType
            count
            percentage
        }
    }
`

export const GET_SALES_BY_PAYMENT_TYPE_FINANCIAL = gql`
    query GetSalesByPaymentType(
        $eventId: ObjectID!
        $startDate: Date!
        $endDate: Date
        $status: String
    ) {
        salesByPaymentType(
            eventId: $eventId
            startDate: $startDate
            endDate: $endDate
            status: $status
        ) {
            type
            count
            percentage
            total
        }
    }
`

export const GET_SALES_BY_PAYMENT_STATUS_FINANCIAL = gql`
    query GetSalesByPaymentStatus(
        $eventId: ObjectID!
        $startDate: Date!
        $endDate: Date
    ) {
        salesByPaymentStatus(
            eventId: $eventId
            startDate: $startDate
            endDate: $endDate
        ) {
            status
            value
            percentage
        }
    }
`

export const GET_SALES_DAILY_OVERRAL_PERFORMANCE_FINANCIAL = gql`
    query GetSalesDailyOverralPerformance(
        $eventId: ObjectID!
        $startDate: Date!
        $endDate: Date
        $byHour: Boolean
    ) {
        salesDailyOverallPerformance(
            eventId: $eventId
            startDate: $startDate
            endDate: $endDate
            byHour: $byHour
        ) {
            date
            count
            volume
        }
    }
`

