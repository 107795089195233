import React from 'react'
import { Switch, useParams } from 'react-router-dom'

import PrivateRoute from '@cms/core/routes/privateRoute'

import PageBuilder from '../pagebuilder'
import {
    Page,
    General,
    Cookies,
    Setup,
    TemplateSelection,
    Seo,
    WebScripts,
    Menu,
    ModalFormStyles
} from '../pages'

import { ROUTES, BASE_URL } from './../constants/routes'
import ModalFormType from '../modals/Form/Form'

const components = {
    config: () => <General />,
    cookies: () => <Cookies />,
    seo: () => <Seo />,
    modalFormStyles: () => <ModalFormStyles />,
    modalForm: () => <ModalFormType />,
    scripts: () => <WebScripts />,
    home: () => <Page name="home" />,
    speaker: () => <Page name="speaker" />,
    sponsor: () => <Page name="sponsor" />,
    indication: () => <Page name="indication" />,
    terms: () => <Page name="terms" />,
    menu: () => <Menu />,
    customPage: () => {
        const params = useParams()
        return <Page name={params.pageKey} />
    }
}

export const PageBuilderRoutes = () => {
    return (
        <Switch>
            {ROUTES.config.map((route) => {
                const { path, exact, name } = route
                return (
                    <PrivateRoute
                        key={path}
                        exact={exact}
                        path={BASE_URL + path}
                        component={components[name]}
                    />
                )
            })}

            {ROUTES.navigations.map((route) => {
                const { path, exact, name } = route
                return (
                    <PrivateRoute
                        key={path}
                        exact={exact}
                        path={BASE_URL + path}
                        component={components[name]}
                    />
                )
            })}

            {ROUTES.pages.map((route) => {
                const { path, exact, name } = route
                return (
                    <PrivateRoute
                        key={path}
                        exact={exact}
                        path={BASE_URL + path}
                        component={components[name]}
                    />
                )
            })}

            <PrivateRoute
                path={BASE_URL + 'page/:pageKey'}
                component={components.customPage}
            />

            <PrivateRoute
                path={BASE_URL + 'modal/:modalId'}
                component={components.modalForm}
            />
        </Switch>
    )
}

export const PagesRoutes = () => {
    return (
        <Switch>
            {/* <PrivateRoute exact path="/evento/:eventId/paginas/" component={Dashboard} /> */}
            <PrivateRoute
                exact
                path="/evento/:eventId/paginas/setup"
                component={Setup}
            />
            <PrivateRoute
                exact
                path="/evento/:eventId/paginas/templates"
                component={TemplateSelection}
            />
            <PrivateRoute
                path="/evento/:eventId/paginas"
                component={PageBuilder}
            />
        </Switch>
    )
}

export const PagesRoutesOfContext = () => {
    return (
        <Switch>
            <PrivateRoute
                exact
                path="/evento/:eventId/paginas/templates"
                component={TemplateSelection}
            />
        </Switch>
    )
}

export default PageBuilderRoutes
