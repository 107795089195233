import React, { useEffect, useState } from 'react'
import { DatePicker as AntDatePicker } from 'antd'
import dayjs from 'dayjs'

const DatePicker = (props) => {
    const { value, onChange } = props
    const [date, setDate] = useState(null)

    const handleChange = (date) => {
        onChange(date.toISOString())
    }

    useEffect(() => {
        const _date = value ? dayjs(new Date(value)) : dayjs(new Date())
        setDate(_date)
    }, [value])

    return (
        <AntDatePicker
            showTime
            value={date}
            needConfirm={false}
            onChange={handleChange}
            format={'DD MMM YYYY HH:mm:ss'}
            style={{ width: '200px' }}
        />
    )
}

export default DatePicker
