import React, { useEffect } from 'react'
import { Form, Row, Input, Divider, Col, Switch, Select } from 'antd'

import { useRef } from 'react'
import { merge } from 'lodash'
import Affix from '@cms/core/components/Affix'
import { usePageBuilderContext } from '../../context/context'
import {
    ButtonConfig,
    ColorsBadges,
    DatePicker,
    PanelFooter,
    Preview,
    RedirectOptions
} from '../../components'
import Title from 'antd/lib/typography/Title'
import { ColForm, Wrapper } from './styles'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_All_EVENT_FORMS_OPTIONS } from '@cms/events/graphql/queries/eventForms'

const ModalFormType = (props) => {
    const [form] = Form.useForm()

    const { site, setSite } = usePageBuilderContext()
    const previewRef = useRef(null)
    const colRef = useRef(null)
    const { event } = usePageBuilderContext()

    const { modalId, eventId } = useParams<{
        modalId: string
        eventId: string
    }>()
    const currentModalIndex = site.modals.findIndex(
        (modal) => modal.id === modalId
    )
    const currentModal = site.modals[currentModalIndex]

    const { data: FormsData } = useQuery(GET_All_EVENT_FORMS_OPTIONS, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })

    const updateModalForm = (values) => {
        const formModal = merge({}, currentModal, {
            configs: merge({}, form.getFieldsValue(), values)
        })

        if (previewRef.current) {
            previewRef.current.handleParentWidth(colRef?.current?.offsetWidth)
            previewRef.current.Emit('editModal', {
                ...formModal
            })
        }
    }

    const onInit = (instance) => {
        previewRef.current = instance

        if (previewRef.current) {
            previewRef.current.Emit('editModal', currentModal)
            previewRef.current.handleParentWidth(colRef.current.offsetWidth)
            previewRef.current.handleParentHeight(600)
        }
    }

    useEffect(() => {
        form.setFieldsValue(currentModal?.configs)
        if (previewRef.current) {
            previewRef.current.Emit('editModal', currentModal)
        }
    }, [currentModal])

    if (!site) return null

    // criando fora as props dessa forma evita erro de typescript
    // pos a mescla de js com ts da alguns problemas de props
    // encontrar melhor maneira, talvez ajudando alguma configs do tsconfig
    const previewPros = {
        onInit: onInit,
        ref: previewRef,
        page: 'home'
    }

    return (
        <Row gutter={24} style={{ padding: '0px 12px' }}>
            <ColForm lg={12} md={12} sm={24}>
                <Form
                    name={`modals.${currentModalIndex}.configs`}
                    form={form}
                    layout="vertical"
                    onValuesChange={updateModalForm}
                >
                    <Title level={2}>Modal: {currentModal.name}</Title>
                    <Wrapper>
                        <Title level={4}>Formulário</Title>
                        <Form.Item
                            name={'formId'}
                            label="Escolha o formulário que será exibido"
                            initialValue={event.formRegister.id}
                        >
                            <Select>
                                {FormsData?.eventForms?.edges.map(
                                    ({ node }) => (
                                        <Select.Option
                                            value={node.id}
                                            key={node.id}
                                        >
                                            {node.name === 'register'
                                                ? 'Formulário de inscrição'
                                                : node.name}
                                        </Select.Option>
                                    )
                                )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name={['box', 'bgColor']}
                            label="Cor de fundo"
                            initialValue={'white'}
                        >
                            <ColorsBadges />
                        </Form.Item>

                        <Form.Item
                            name={['box', 'textColor']}
                            label="Cor do texto"
                            initialValue={'black'}
                        >
                            <ColorsBadges />
                        </Form.Item>

                        <RedirectOptions
                            initialValue={{ page: 'indication', mode: 'page' }}
                            excludeModes={['formModal', 'modal']}
                        />
                    </Wrapper>

                    <Divider orientation="left">Título</Divider>

                    <Form.Item name={['headline', 'text']} label="Texto">
                        <Input.TextArea />
                    </Form.Item>

                    <ButtonConfig
                        fieldPath={['button']}
                        inner={true}
                        {...props}
                    />

                    <Divider orientation="left">Contador</Divider>

                    <Form.Item
                        name={['countdown', 'enabled']}
                        label="Exibir contador"
                        initialValue={true}
                    >
                        <Switch />
                    </Form.Item>

                    <Form.Item name={['countdown', 'text']} label="Texto">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name={['countdown', 'textColor']}
                        label="Cor do contador"
                    >
                        <ColorsBadges />
                    </Form.Item>

                    <Form.Item name={['countdown', 'date']}>
                        <DatePicker />
                    </Form.Item>

                    <PanelFooter customPage setSection={() => {}} />
                </Form>
            </ColForm>
            <Col lg={12} md={12} sm={24} ref={colRef}>
                <Affix offsetTop={104}>
                    <div>
                        <div>
                            <Preview {...previewPros} />
                        </div>
                    </div>
                </Affix>
            </Col>
        </Row>
    )
}

export default ModalFormType

