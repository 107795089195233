import React, { useState } from 'react'
import {
    message,
    Form,
    Input,
    Select,
    InputNumber,
    Divider,
    Row,
    Col,
    Typography,
    Button,
    Checkbox
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useApolloClient } from '@apollo/client'

import { CrudForm, CrudModal, useCrudForm } from '@cms/core/components/Crud'

import { addToList } from '@cms/core/graphql/utils'
import { GET_BRAND_PLAN } from '@cms/events/graphql/queries/brandPlan'
import {
    CREATE_BRAND_PLAN,
    UPDATE_BRAND_PLAN
} from '@cms/events/graphql/mutations/brandPlan'

import { RadioGroup, RadioButton } from '@cms/core/components/Radio'
import { displayIf } from '@cms/core/utils'

const { Paragraph } = Typography

const BrandPlansModal = ({ params, ...props }) => {
    const [hostType, setHostType] = useState('DIGITAL')

    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_BRAND_PLAN,
                variables: { id }
            })

            setHostType(data.brandPlan.hostType)

            return data.brandPlan
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_BRAND_PLAN,
                update: addToList({ list: 'brandPlans', Type: 'BrandPlan' }),
                variables: formData
            })

            message.success('Plano cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_BRAND_PLAN,
                variables: { id, ...formData }
            })

            message.success('Plano atualizado com sucesso!')
        }
    })

    const toggleHostType = (event) => {
        setHostType(event.target.value)
        if (event.target.value === 'ONSTAGE') {
            form.setFieldsValue({ type: undefined })
            form.setFieldsValue({ priority: null })
        }
    }

    // Benefício

    return (
        <CrudModal
            title={isEdit ? 'Editar Plano' : 'Novo Plano'}
            {...modalProps}
            {...props}
        >
            <CrudForm
                layout={'vertical'}
                form={form}
                initialValues={{ hostType: hostType }}
            >
                <Form.Item name="hostType">
                    <RadioGroup
                        onChange={toggleHostType}
                        defaultValue={hostType}
                        buttonStyle="solid"
                    >
                        <RadioButton value="DIGITAL">Digital</RadioButton>
                        <RadioButton noBorder value="HYBRID">
                            Híbrido
                        </RadioButton>
                        <RadioButton value="ONSTAGE">Presencial</RadioButton>
                    </RadioGroup>
                </Form.Item>

                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Tipo"
                    name={'type'}
                    placeholder="Selecione um tipo"
                    style={({ width: '100%' }, displayIf(hostType, 'ONSTAGE'))}
                    rules={[{ required: true, message: 'Tipo é obrigatório!' }]}
                >
                    <Select>
                        {['XG', 'G', 'M', 'P', 'PP'].map((value) => (
                            <Select.Option key={value} value={value}>
                                {value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    style={displayIf(hostType, 'ONSTAGE')}
                    label="Prioridade (Ordena os planos do maior para o menor)"
                    name="priority"
                    {...getError('priority')}
                >
                    <InputNumber style={{ width: '100%' }} />
                </Form.Item>

                <Divider orientation="left">Ações card</Divider>
                <Form.Item
                    style={{
                        ...displayIf(hostType, 'ONSTAGE'),
                        flexDirection: 'row',
                        alignItems: 'baseline'
                    }}
                    valuePropName="checked"
                    initialValue={false}
                    label="Acessar página exclusiva"
                    name="sponsorPage"
                    {...getError('sponsorPage')}
                >
                    <Checkbox style={{ marginLeft: '10px' }} />
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                        const sponsorPageActive = getFieldValue(['sponsorPage'])

                        return !sponsorPageActive ? (
                            <Form.Item
                                name={'sponsorLink'}
                                valuePropName="checked"
                                initialValue={false}
                                label="Acessar link externo"
                                style={{
                                    ...displayIf(hostType, 'ONSTAGE'),
                                    flexDirection: 'row',
                                    alignItems: 'baseline'
                                }}
                            >
                                <Checkbox style={{ marginLeft: '10px' }} />
                            </Form.Item>
                        ) : null
                    }}
                </Form.Item>
                <Divider orientation="left">Benefícios do plano</Divider>
                <Form.List name="assets">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <div key={`assets.${index}`}>
                                    <Row
                                        className="actionsBox"
                                        gutter={24}
                                        align="middle"
                                    >
                                        <Col span={22}>
                                            <Paragraph>
                                                Benefício {index + 1}
                                            </Paragraph>
                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label="Nome"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Nome é obrigatório!'
                                                            }
                                                        ]}
                                                        name={[
                                                            field.name,
                                                            'name'
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        {...field}
                                                        label="Descrição"
                                                        name={[
                                                            field.name,
                                                            'description'
                                                        ]}
                                                    >
                                                        <Input.TextArea />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={2}>
                                            <MinusCircleOutlined
                                                style={{ marginTop: 20 }}
                                                onClick={() =>
                                                    remove(field.name)
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        add({})
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    <PlusOutlined /> Adicionar
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </CrudForm>
        </CrudModal>
    )
}

export default BrandPlansModal
