import { COLORS } from '@cms/core/themes'
import { Col } from 'antd'
import styled from 'styled-components'
import { Wrapper as DefaultWrapper } from '../../styles'

export const ColForm = styled(Col)`
    background-color: ${COLORS.bgLight};
    padding: 16px;
    border-radius: 20px;

    .list-label {
        font-family: HelveticaNowRegular, sans-serif;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.85);
    }

    .affix-form-footer {
        margin-left: -12px;
        margin-right: -12px;
    }

    h2.ant-typography {
        margin: 0px 0px 10px 0px;
        color: #fff;
    }
`

export const Wrapper = styled(DefaultWrapper)`
    margin-bottom: 20px;
    background-color: transparent;
    border: 2px solid rgba(228, 236, 255, 0.1);
    h4.ant-typography {
        margin: 0px 0px 10px 0px;
        color: #fff;
    }
`
