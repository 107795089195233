import React from 'react'
import { Row } from 'antd'

import {
    DashboardTitle,
    IconWrapper
} from '@cms/events/components/Dashboard/styles'

import MainNumber from './MainNumber'
import MainNumberV2 from './MainNumberV2'
import CompareCircle from './CompareCircle'
import CompareLines from './CompareLines'

import { StyledTDDashboardCard } from './styles'

const DashboardCard = ({ children, titleSmall, ...props }) => {
    const { title, options, icon: Icon } = props
    return (
        <StyledTDDashboardCard>
            <Row style={{ marginBottom: '30px' }} justify="space-between">
                <Row align="middle">
                    <IconWrapper>
                        <Icon style={{ color: '#fff' }} />
                    </IconWrapper>
                    <DashboardTitle titleSmall={titleSmall}>
                        {title}
                    </DashboardTitle>
                </Row>
                {options}
            </Row>
            {children}
        </StyledTDDashboardCard>
    )
}

export default DashboardCard
export { MainNumber, CompareCircle, CompareLines, MainNumberV2 }

