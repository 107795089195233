import React from 'react'
import { pathToRegexp } from 'path-to-regexp'
import { get } from 'lodash'

import { IAppModule } from '@cms/core/types/app'
import { DefaultRootState } from 'react-redux'
import { sidebarIcons } from '../RemixIcons'
import { exact } from 'prop-types'

export const crudActions = (
    module: string,
    include = ['read', 'write', 'delete']
) => {
    const actions: IAppModule[] = []

    if (include.includes('read')) {
        actions.push({
            type: 'action',
            name: 'Visualizar',
            autoSelect: true,
            icon: sidebarIcons.read,
            module: module + ':read'
        })
    }

    if (include.includes('write')) {
        actions.push({
            type: 'action',
            name: 'Criar / Editar',
            icon: sidebarIcons.write,
            module: module + ':write'
        })
    }

    if (include.includes('delete')) {
        actions.push({
            type: 'action',
            name: 'Excluir',
            icon: sidebarIcons.delete,
            module: module + ':delete'
        })
    }

    return actions.length ? actions : null
}

export const matchPathItem = (item: IAppModule, pathname: string) => {
    if (item.excludePaths?.some((o) => pathToRegexp(o).test(pathname))) {
        return false
    }

    return pathToRegexp(
        item.pathMatch ? item.pathMatch : item.path + (item.exact ? '' : '(.*)')
    ).test(pathname)
}

export const getCurrentMenu = (items: IAppModule[], pathname: string) => {
    const getItem = (_items: IAppModule[]) => {
        for (const item of _items) {
            if (item.children) {
                const subItem = getItem(item.children)
                if (subItem) {
                    return subItem
                }
            }

            if (item.root) continue

            if (item.path && matchPathItem(item, pathname)) {
                return item
            }
        }
    }

    return getItem(items)
}

export const getCurrentMainModule = (
    items: IAppModule[],
    pathname: string,
    ignoreRoot = true
) => {
    // Função recursiva para buscar no array de itens
    const getItem = (_items: IAppModule[], parent: IAppModule = null) => {
        for (const item of _items) {
            // Se o item fizer match
            if (item.path && matchPathItem(item, pathname)) {
                // Caso o ignoreRoot seja false, o root não será ignorado
                if (!ignoreRoot) {
                    return item
                }

                if (parent && !parent.root) {
                    return parent
                }

                // Se o item não for root, retorne ele
                if (!item.root) {
                    return item
                }
            }

            // Se tiver filhos, continue buscando recursivamente
            if (item.children && item.children.length > 0) {
                const childItem = getItem(item.children, item)
                if (childItem) {
                    return childItem
                }
            }
        }
    }

    const mainMenu = getItem(items)

    return mainMenu
}

export const getCurrentModules = (
    items: IAppModule[],
    pathname: string,
    ignoreRoot = true
): IAppModule[] => {
    let deepestItem = null

    // Função recursiva para percorrer os itens
    const findDeepestMatch = (_items: IAppModule[]) => {
        for (const item of _items) {
            // Verifica se o item deve ser considerado
            if (
                (!ignoreRoot || !item.root) &&
                item.path &&
                matchPathItem(item, pathname)
            ) {
                deepestItem = item
            }

            // Se o item tiver filhos, busca recursivamente aumentando a profundidade
            if (item.children && item.children.length > 0) {
                findDeepestMatch(item.children)
            }
        }
    }

    findDeepestMatch(items)

    if (!deepestItem) return []

    return [...new Set(getParentModules(deepestItem.module, items))]
}

export const getTreeData = (items: IAppModule[]) => {
    const convert = (_items: IAppModule[], parent?: IAppModule) => {
        return _items
            .filter((o) => !o.disablePermissions)
            .map((item) => {
                const aliasNode = item.children?.find((o) => o.alias)
                if (aliasNode) {
                    item = { ...aliasNode }
                    item.alias = false
                }

                const { children, ...rest } = item
                const autoSelectItems = parent?.children
                    ?.filter((o) => o.autoSelect)
                    .map((o) => o.module)

                return {
                    title: item.name,
                    key: item.module,
                    value: item.module,
                    disabled: item.required,
                    parentKey: parent?.module,
                    autoSelectItems,
                    ...rest,
                    icon: item.icon?.filled,
                    paremt: parent?.module,
                    children: children ? convert(children, item) : []
                }
            })
            .filter((o) => !o.alias)
    }

    return convert(items)
}

export const getDefaultModules = (items: IAppModule[]) => {
    const convert = (_items: IAppModule[]) => {
        const newItems = []

        _items.forEach((item) => {
            if (item.required && !item.children) {
                newItems.push(item.module)
                return
            }

            if (item.children) {
                newItems.push(...convert(item.children))
            }
        })

        return newItems
    }

    return convert(items)
}

export const getPersistedModules = (items: IAppModule[]) => {
    const convert = (_items: IAppModule[]) => {
        const newItems = []

        _items.forEach((item) => {
            if (!item.children || !item.children.length) {
                newItems.push(item.module)
                return
            }

            if (item.children) {
                newItems.push(...convert(item.children))
            }
        })

        return newItems
    }

    return convert(items)
}

export const findModule = (
    keyValue: string,
    items: IAppModule[],
    options = {} as {
        recursive?: boolean
        key?: string
    }
) => {
    const { recursive = true, key = 'module' } = options

    for (let i = 0; i < items?.length; i++) {
        let node = items[i]
        if (node[key] === keyValue) {
            return node
        }
        if (recursive && node.children) {
            node = findModule(keyValue, node.children, options)
            if (node) return node
        }
    }
}

export const GetFirstChildrenPath = (
    moduleKey: string,
    items: IAppModule[]
) => {
    const menu = findModule(moduleKey, items)
    return menu.children?.[0]?.path || menu.path
}

export const IsEnablePermissions = (item: IAppModule, items: IAppModule[]) => {
    if (item.disablePermissions) return false

    return getParentModules(item.module, items).every(
        (o) => !o.disablePermissions
    )
}

export const getParentModules = (
    moduleKey: string,
    items: IAppModule[],
    parents: IAppModule[] = []
): IAppModule[] => {
    for (let i = 0; i < items.length; i++) {
        const node = items[i]
        if (node.module === moduleKey) {
            let newNode = { ...node }
            if (node.children) {
                const aliasNode = node.children.find((o) => o.alias)
                if (aliasNode) {
                    newNode = { ...aliasNode, alias: false }
                }
            }
            return [...parents, newNode]
        }
        if (node.children) {
            parents.push(node)
            const result = getParentModules(moduleKey, node.children, parents)
            if (result.length) return result
            parents.pop()
        }
    }
    return []
}

export const getModules = (items: IAppModule[]) => {
    return items.reduce((acc, cur) => {
        if (cur.children) {
            return [...acc, cur, ...getModules(cur.children)]
        }
        return [...acc, cur]
    }, [])
}

export const uniqueModules = (items: IAppModule[]) => {
    let included = []

    const result = items.reduce((acc, cur) => {
        if (!cur.alias && included.includes(cur.module)) return acc
        included.push(cur.module)
        return [...acc, cur]
    }, [])

    included = []

    return result
}

export const selectPlanModules = (
    state: DefaultRootState,
    items: IAppModule[]
) => {
    const billingPlan = get(state, 'customer.billingPlan')

    let planModules = []

    if (!billingPlan || billingPlan.fullAccess) {
        planModules = getModules(items) || []
    } else {
        planModules =
            billingPlan?.modules.flatMap((module: string) => [
                ...getParentModules(module, items)
            ]) || []
    }

    return [...uniqueModules(planModules)]
}

export const selectPlanModuleskey = (
    state: DefaultRootState,
    items: IAppModule[]
) => {
    const _modules = selectPlanModules(state, items)
    return [...new Set(_modules.map((item) => item.module))]
}

export const selectFullAccess = (state: DefaultRootState) => {
    const isFullAccess =
        ['superadmin', 'sponsor'].includes(get(state, 'user.role')) ||
        get(state, 'user.profile.fullAccess')

    return isFullAccess
}

export const selectModules = (state: DefaultRootState, items: IAppModule[]) => {
    const profileModules = get(state, 'user.profile.modules', [])

    const isFullAccess = selectFullAccess(state)

    let _modules = []

    if (isFullAccess) {
        _modules = getModules(items)
    } else {
        _modules = profileModules.flatMap((module: string) => [
            ...getParentModules(module, items)
        ])
    }

    const newModules = [...uniqueModules(_modules)].map((item) => {
        const { children, ...rest } = item
        return { ...rest, _children: children }
    })

    return newModules
}

export const selectModulesKey = (
    state: DefaultRootState,
    items: IAppModule[]
) => {
    const _modules = selectModules(state, items)
    return [...new Set(_modules.map((module) => module.module))]
}

export const selectModulesPath = (
    state: DefaultRootState,
    items: IAppModule[]
) => {
    const _modules = selectModules(state, items)

    return _modules
        .map((module) => module.pathMatch || module.path)
        .filter((o) => o)
}

export const getModulePath = (moduleKey: string, items: IAppModule[]) => {
    const module = findModule(moduleKey, items)
    if (!module) return
    return module.path || module.children?.[0]?.path
}

export const selectMenu = (state: DefaultRootState, items: IAppModule[]) => {
    const _modules = selectModulesKey(state, items)

    // recursive filter
    const recursiveFilter = (items: IAppModule[], skip = false) => {
        const newItems = []
        items.forEach((item) => {
            if (
                _modules.includes(item.module) ||
                item.disablePermissions ||
                skip
            ) {
                const children = item.children
                    ? recursiveFilter(
                          item.children,
                          skip || item.disablePermissions
                      )
                    : undefined
                newItems.push({
                    ...item,
                    path: item.path || children?.[0]?.path,
                    pathMatch: item.pathMatch || children?.[0]?.pathMatch,
                    exact: item.exact || children?.[0]?.exact,
                    children
                })
            }
        })
        return newItems
    }

    return recursiveFilter([...items])
}
