import styled from 'styled-components'
import { Flex, Layout, Select } from 'antd'
import { Link } from 'react-router-dom'

import { COLORS } from '@cms/core/themes'
import { darken } from 'polished'

const { Header } = Layout

export const HeaderStyled = styled(Header)`
    padding: 0 10px 0 20px;
    margin: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    border-radius: 8px;
    background: ${COLORS.bgLight};

    ul:last-child {
        width: 100%;
        justify-content: flex-end;
        display: flex;
    }

    .ant-menu.ant-menu-root:focus-visible {
        box-shadow: none;
    }

    .header-menus {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;

        .header-main-menu {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            background: transparent;

            .ant-menu-item-selected {
                background: transparent;
                a {
                    background-color: ${darken(0.1, COLORS.secondary)};
                    color: ${COLORS.text};
                }
            }

            .ant-menu-item-selected::after {
                border-bottom-color: transparent;
            }
            .ant-menu-item {
                display: inline-flex;
            }
        }

        .header-user-menu {
            background: transparent;
            padding: 0 10px;
            margin-left: 10px;
        }
    }
`

export const ModuleName = styled.div`
    min-width: 100px;
    position: relative;
    margin-left: 20px;
    line-height: 1.5;
    display: flex;
    flex-direction: column;

    .current-event-page-arrow {
        right: 0;
        left: -20px;
        top: 23px;
    }

    .current-page-arrow {
        left: 0;
        left: -16px;
        top: 20px;
    }

    .ant-page-header-heading-sub-title {
        font-size: 10px;
        align-self: start;
        margin-bottom: -5px;
        color: ${COLORS.textLight};
    }

    .ant-page-header-heading-title {
        /* opacity: 0.75; */
    }

    &:before {
        content: '';
        position: absolute;
        width: 2px;
        height: 80%;
        background: ${COLORS.textDark};
        left: -15px;
        top: 10%;
    }
`
interface ISelectStyled {
    size?: 'full' | 'default'
    margin?: 'large' | 'default'
}

export const SelectStyled = styled(Select)<ISelectStyled>`
    width: ${(props) => (props.size === 'full' ? '100%' : 'auto')};
    margin-bottom: ${(props) => (props.margin === 'large' ? '10px' : '0px')};
    padding: ${(props) => (props.size === 'full' ? '0 8px' : '0px')};
`

export const DropDownStyled = styled.div`
    .ant-btn {
        margin: 5px 10px;
        width: calc(100% - 20px);
    }
`

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: center;
    padding: 20px 0;
    height: 60px;

    .ant-menu-item-selected {
        background-color: transparent !important;
    }
    .ant-menu-vertical {
        border-right: none !important;
        padding-left: 0;
        padding-right: 0;

        .ant-menu-submenu-title {
            padding-left: 0;
            margin-left: -2px;

            .ant-menu-submenu-arrow {
                right: 0;
            }
        }
    }

    a {
        ${ModuleName} {
            cursor: pointer;
            color: ${COLORS.text};
            .ant-page-header-heading-title {
                opacity: 1;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
`

export const AppTitle = styled.h2`
    font-size: 14px;
    font-weight: 1;
    letter-spacing: 5.25px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1;
    opacity: 0.7;
    text-transform: uppercase;
`

export const AppCard = styled(Link)`
    svg {
        width: 60px;
        height: 25px;
        margin: 0 auto;
    }
`

export const AppLogo = styled.div`
    display: flex;
    cursor: pointer;

    img,
    svg {
        margin-right: 10px;
        height: 20px;
        max-width: 50px;
        object-fit: contain;
    }

    ${AppTitle} {
        margin-top: 5px;
        font-size: 10px;
    }
`

export const AppGrid = styled.div`
    & :last-child {
        margin-bottom: 0 !important;
    }
`

export const MenuItemStyled = styled.div`
    display: flex;
    gap: 8px;
    min-width: 100%;
    align-items: center;
    justify-content: flex-start;
    svg {
        width: 14px;
        height: 14px;
    }
`
