import React from 'react'
import { BigCount, Content, MetricTitle, Wrapper } from './styles'

export default function FinancialOrderMetricCard({
    title,
    data,
    currency,
    align
}) {
    return (
        <Wrapper align={align}>
            <Content>
                <MetricTitle>{title}</MetricTitle>
                <BigCount>
                    <span>{currency}</span>
                    <span>{data}</span>
                </BigCount>
            </Content>
        </Wrapper>
    )
}

