import { DatePicker, Select } from 'antd'
import React, { useState } from 'react'

import dayjs from 'dayjs'

const today = dayjs()

const { RangePicker } = DatePicker

export default function FinancialFilterByDate({
    dateOption,
    setDateOption,
    setDateRange
}) {
    const [dropdownOpen, setDropdownOpen] = useState(false)

    let _dateOption = dateOption

    const isCustomOption = _dateOption === 'custom' || _dateOption === 'perDay'

    const handleDateChange = (value) => {
        _dateOption = value
        setDateOption(value)

        const yesterday = dayjs().subtract(1, 'day')
        const last7Days = dayjs().subtract(7, 'day')
        const last15Days = dayjs().subtract(15, 'day')
        const lastMonthStart = dayjs().subtract(1, 'month').startOf('month')
        const lastMonthEnd = dayjs().subtract(1, 'month').endOf('month')

        if (value === 'custom' || value === 'perDay') {
            setDropdownOpen(true)
        } else {
            setDropdownOpen(false)
        }

        switch (value) {
            case 'today':
                setDateRange({
                    startDate: today.format('YYYY-MM-DD'),
                    endDate: today.add(1, 'day').format('YYYY-MM-DD')
                })
                break
            case 'yesterday':
                setDateRange({
                    startDate: yesterday.format('YYYY-MM-DD'),
                    endDate: today.format('YYYY-MM-DD')
                })
                break
            case 'last7days':
                setDateRange({
                    startDate: last7Days.format('YYYY-MM-DD'),
                    endDate: today.format('YYYY-MM-DD')
                })
                break
            case 'last15days':
                setDateRange({
                    startDate: last15Days.format('YYYY-MM-DD'),
                    endDate: today.format('YYYY-MM-DD')
                })
                break
            case 'lastMonth':
                setDateRange({
                    startDate: lastMonthStart.format('YYYY-MM-DD'),
                    endDate: lastMonthEnd.format('YYYY-MM-DD')
                })
                break
        }
    }

    const customDropdownRender = (menu) => (
        <div>
            {menu}
            {_dateOption === 'perDay' && (
                <div style={{ padding: '8px' }}>
                    <DatePicker
                        needConfirm={false}
                        style={{ width: '100%' }}
                        format={'YYYY-MM-DD'}
                        placeholder="Data"
                        onChange={(_, dateString) => {
                            if (dateString) {
                                setDateRange({
                                    startDate: dateString,
                                    endDate: dayjs(dateString)
                                        .add(1, 'day')
                                        .format('YYYY-MM-DD')
                                })
                                setDropdownOpen(false)
                            }
                        }}
                    />
                </div>
            )}

            {_dateOption === 'custom' && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '8px'
                    }}
                >
                    <RangePicker
                        needConfirm={false}
                        style={{ width: '100%' }}
                        format={'YYYY-MM-DD'}
                        placeholder={['Início', 'Fim']}
                        onChange={(_, dateString) => {
                            if (dateString[0]) {
                                setDateRange({
                                    startDate: dateString[0],
                                    endDate: dateString[1]
                                })
                                setDropdownOpen(false)
                            }
                        }}
                    />
                </div>
            )}
        </div>
    )

    return (
        <div style={{ marginBottom: '12px' }}>
            <Select
                placeholder="Período"
                value={dateOption}
                onChange={handleDateChange}
                style={{ minWidth: isCustomOption ? '280px' : '155px' }}
                dropdownRender={customDropdownRender}
                open={dropdownOpen}
                onDropdownVisibleChange={(visible) => {
                    if (['custom', 'perDay'].includes(_dateOption)) {
                        setDropdownOpen(true)
                    } else {
                        setDropdownOpen(visible)
                    }
                }}
            >
                <Select.Option value="today">Hoje</Select.Option>
                <Select.Option value="yesterday">Ontem</Select.Option>
                <Select.Option value="last7days">Últimos 7 dias</Select.Option>
                <Select.Option value="last15days">
                    Últimos 15 dias
                </Select.Option>
                <Select.Option value="lastMonth">Últimos mês</Select.Option>
                <Select.Option value="custom">Intervalo</Select.Option>
                <Select.Option value="perDay">Específico</Select.Option>
            </Select>
        </div>
    )
}

