import React, { useState } from 'react'

import Card, { CompareLines } from '@cms/events/pages/Events/Card'
import { StarOutlined } from '@ant-design/icons'
import { Empty, Select } from 'antd'
import { GET_SALES_BY_TICKET_TYPE_FINANCIAL } from '@cms/events/graphql/queries/financial'
import { useQuery } from '@apollo/client'

export default function FinancialTickets({ eventId, dateRange }) {
    const [type, setType] = useState('numeric')

    const { data } = useQuery(GET_SALES_BY_TICKET_TYPE_FINANCIAL, {
        variables: { eventId, ...dateRange }
    })

    const values =
        data?.salesByTicketType?.map((sales) => ({
            name: sales.ticketType,
            percent: `${sales.percentage}%`,
            value: type === 'numeric' ? sales.count : `${sales.percentage}%`
        })) || []

    return (
        <Card
            icon={StarOutlined}
            title="Tipos de ingresso"
            options={
                <Select
                    placeholder="Selecione"
                    value={type}
                    onChange={(value) => setType(value)}
                >
                    <Select.Option value="numeric">
                        Valor númerico
                    </Select.Option>
                    <Select.Option value="percent">Parcentual</Select.Option>
                </Select>
            }
        >
            {values.length > 0 ? (
                <CompareLines title="Tipos de ingresso" values={values} />
            ) : (
                <Empty description="Não há dados" />
            )}
        </Card>
    )
}

