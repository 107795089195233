import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import {
    Menu,
    Typography,
    Avatar,
    Popover,
    Dropdown,
    MenuProps,
    Flex
} from 'antd'

import { TOKEN_KEY } from '@cms/core/utils/auth'

import { UserOutlined } from '@ant-design/icons'
import {
    findModule,
    getCurrentMainModule,
    getCurrentModules,
    getModulePath
} from '../Sidebar/common'
import { TDCard } from '../TDCards'
import Logo from '@cms/core/components/Logo'
import { AllAppsModules, Apps } from '@cms/core/Apps'
import HeaderCrumb from './HeaderCrumb'
import RootLink from '../RootLink'
import { isModuleAllowed } from '../CheckModule'

import {
    AppGrid,
    AppLogo,
    AppTitle,
    HeaderStyled,
    HeaderWrapper,
    MenuItemStyled
} from './styles'
import { useApp } from '../Providers/AppProvider/Hooks/useApp'
import { ItemType, MenuItemType } from 'antd/es/menu/interface'

const { Text } = Typography

interface IAppLogoProps {
    AfterModuleComponent?: React.ComponentType
    BeforeModuleComponent?: React.ComponentType
    menuItems?: ItemType<MenuItemType>[]
    children?: React.ReactNode
}

const Header = function ({
    AfterModuleComponent,
    BeforeModuleComponent,
    menuItems,
    children
}: IAppLogoProps) {
    const app = useApp()

    const location = useLocation()

    const state = useSelector((state) => state) || {}

    const avatar = useSelector((state) => state.user.avatar) || null
    const user = useSelector((state) => state.user) || null
    const customer = useSelector((state) => state.customer) || {}

    const MainModules: MenuProps['items'] = useMemo(() => {
        return findModule('mainapp', Apps.main.modules)
            .children.filter((item) => {
                if (item.disablePermissions) {
                    return true
                }
                return isModuleAllowed(state, item.module)
            })
            .map((item) => {
                return {
                    key: item.module,
                    label: (
                        <RootLink
                            to={
                                Apps.main.basePath +
                                getModulePath(item.module, Apps.main.modules)
                            }
                            checkMainApp
                        >
                            <MenuItemStyled>
                                {item.icon?.filled}
                                <Text>{item.name}</Text>
                            </MenuItemStyled>
                        </RootLink>
                    )
                }
            })
    }, [state])

    const { curretMainMenu, selectedMenus } = useMemo(() => {
        const curretMainMenu = getCurrentMainModule(
            AllAppsModules,
            location.pathname
        )
        const selectedMenus = getCurrentModules(
            AllAppsModules,
            location.pathname
        ).map((o) => o.module)
        return {
            curretMainMenu,
            selectedMenus: [...new Set(selectedMenus)]
        }
    }, [location.pathname])

    const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
        localStorage.removeItem(TOKEN_KEY)
    }

    const Profile = () => {
        if (avatar) return <Avatar src={avatar.url} />
        else return <Avatar icon={<UserOutlined />} />
    }

    if (!user || !customer) return null

    const itemsUser: MenuProps['items'] = [
        {
            key: 'Name',
            label: (
                <Flex gap="small">
                    <Profile />
                    <div>
                        <Text strong>{user.name}</Text>
                        <br />
                        <Text type="secondary">{customer.name}</Text>
                    </div>
                </Flex>
            )
        },
        {
            type: 'divider'
        },
        ...MainModules,
        {
            type: 'divider'
        },
        {
            key: 'logout',
            label: (
                <RootLink to={`/`} onClick={handleLogout}>
                    Logout
                </RootLink>
            )
        }
    ]

    const EnabledApps = useMemo(() => {
        return Object.keys(Apps)
            .filter((key: string) => app.id != key)
            .filter((key: string) => {
                const item = Apps[key as keyof typeof Apps]
                return !item.hidden && isModuleAllowed(state, item.id)
            })
            .filter((key) => {
                const App = Apps[key as keyof typeof Apps]
                return !App.checkEnable || App.checkEnable(state)
            })
    }, [state])

    const AppsContent = () => {
        return (
            <AppGrid>
                {EnabledApps.map((key: string) => {
                    const item = Apps[key as keyof typeof Apps]
                    return (
                        <RootLink
                            to={item.basePath}
                            style={{
                                display: 'block',
                                marginBottom: 10
                            }}
                        >
                            <TDCard
                                hoverable
                                styles={{
                                    body: {
                                        padding: 10
                                    }
                                }}
                            >
                                <AppLogo>
                                    <Logo />
                                    <AppTitle>{item.name}</AppTitle>
                                </AppLogo>
                            </TDCard>
                        </RootLink>
                    )
                })}
            </AppGrid>
        )
    }

    return (
        <HeaderStyled theme={'light'} className="header">
            <HeaderWrapper>
                <Popover
                    placement="bottomLeft"
                    content={EnabledApps.length ? AppsContent : null}
                >
                    <Link to={'/'}>
                        <AppLogo>
                            <Logo />
                            <AppTitle>{app.name}</AppTitle>
                        </AppLogo>
                    </Link>
                </Popover>
                {AfterModuleComponent && <AfterModuleComponent />}
                {curretMainMenu && (
                    <HeaderCrumb
                        title={curretMainMenu.name}
                        subtitle={'Módulo'}
                    />
                )}
                {BeforeModuleComponent && <BeforeModuleComponent />}
            </HeaderWrapper>

            <div className="header-menus">
                <Menu
                    className="header-main-menu"
                    selectedKeys={selectedMenus}
                    mode="horizontal"
                    items={menuItems}
                />
                {children}

                <Dropdown
                    className="header-user-menu"
                    placement="bottomRight"
                    menu={{ items: itemsUser, style: { width: 250 } }}
                >
                    <a onClick={(e) => e.preventDefault()}>
                        <Profile />
                    </a>
                </Dropdown>
                {/* <Menu
                    className="header-user-menu"
                    mode="horizontal"
                    items={itemsUser}
                /> */}
            </div>
        </HeaderStyled>
    )
}

export default Header
