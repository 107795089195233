import styled from 'styled-components'

import { Typography } from 'antd'

const { Title } = Typography

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const MetricTitle = styled.h1`
    margin-top: 8px;
`

export const BigCount = styled(Title)`
    display: flex;
    align-items: center;
    gap: 8px;

    & > span:first-child {
        font-size: 12px;
    }

    & > span:last-child {
        font-size: 20px;
        margin-top: 0;
        letter-spacing: -1px;
        color: #fff;

        @media (min-width: 1600px) {
            font-size: 24px;
        }
    }
`

export const Wrapper = styled.div`
    display: flex;
    align-items: left;
    justify-content: center;
    height: auto;
    width: 100%;

    h1 {
        text-align: ${(props) => (props.align ? props.align : 'left')};
        font-size: 12px;

        @media (min-width: 1600px) {
            font-size: 13px;
        }
    }
`

