import React, { createContext, useCallback } from 'react'

import { IUser } from '@cms/core/types/user'
import { isModuleAllowed } from '../../CheckModule'
import { useSelector } from 'react-redux'

interface IModulePermissionContext {
    rootModuleKey?: string
    hasActions?: (action: 'read' | 'write' | 'delete') => boolean
}

const ModulePermissionContext = createContext<IModulePermissionContext>({
    rootModuleKey: null
})

interface ModulePermissionProviderProps {
    children?: React.ReactNode
    defaultRootModule?: string
    user?: IUser
    module?: string
}

const useModulePermissions = () => {
    return React.useContext(ModulePermissionContext)
}

const ModulePermissionProvider = ({
    children,
    module
}: ModulePermissionProviderProps) => {
    const state = useSelector((state) => state)

    const hasActions = useCallback(
        (action) => {
            return !module || isModuleAllowed(state, module, action)
        },
        [state, module]
    )

    return (
        <ModulePermissionContext.Provider
            value={{
                rootModuleKey: module,
                hasActions
            }}
        >
            {children}
        </ModulePermissionContext.Provider>
    )
}

export {
    ModulePermissionContext,
    ModulePermissionProvider,
    useModulePermissions
}
